§<template>
  <div class="details">
    <div class="details--header" @click="editMode = !editMode">
      <h2 class="details--header-title">Récapitulatif de l'estimation</h2>
      <EditButton
        v-if="$store.getters['project/getCurrentProjectId']"
        :editMode="editMode"
        :loading="editButtonLoading"
        @onSave="$emit('onSave', model)"
      />
    </div>
    <div class="details--body">
      <keep-alive>
        <component
          :is="component"
          :model="{...project, roomsRenovate: rooms}"
          :loading="editButtonLoading"
          :editMode="editMode"
          @buttonEvent="handleEditButton"
          @updateModel="(payload) => Object.assign(model, payload)"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { ProjectModel } from "@/models/project.model.js";
export default {
  name: "SummaryOfEstimate",
  components: {
    SummaryOfEstimateEdit: () => import("./SummaryOfEstimateEdit.vue"),
    SummaryOfEstimateView: () => import("./SummaryOfEstimateView.vue"),
    EditButton: () => import("~ui/EditButton/EditButton.vue"),
  },
  props: {
    editButtonLoading: {
      type: Boolean,
    },
    rooms: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      editMode: false,
      model: {},
    };
  },
  computed: {
    project() {
      const currentProject = this.$store.getters["project/getCurrentProject"]
      return currentProject
        ? new ProjectModel(currentProject)
        : new ProjectModel();
    }, 
    component() {
      return this.editMode ? "SummaryOfEstimateEdit" : "SummaryOfEstimateView";
    },
  },
  methods: {
    handleEditButton(mode) {
      this.$emit('onSave', this.model)
      this.editMode = mode
    }
  }
};
</script>

<style></style>
